
export default () => {
    const pageNameID = "interna-ex";
    const loadPage = document.getElementById(pageNameID);

    const filter_applay = document.getElementById("tipo_exame");

    const getChangerCitie = params => {
        filter_applay.addEventListener("change", function() {
            getConstruct(filter_applay.value);
        });


    };

    const loadCurrent = () =>{
        document.addEventListener("DOMContentLoaded", function(event) {
            getConstruct(filter_applay.value);
        });

    }


    const detectmob = () => {
        if( navigator.userAgent.match(/Android/i)
        || navigator.userAgent.match(/webOS/i)
        || navigator.userAgent.match(/iPhone/i)
        || navigator.userAgent.match(/iPad/i)
        || navigator.userAgent.match(/iPod/i)
        || navigator.userAgent.match(/BlackBerry/i)
        || navigator.userAgent.match(/Windows Phone/i)
        ){
           return true;
         }
        else {
           return false;
         }
    }

    const getConstruct = async (x) => {
        try {


            var tipo_cl = document.getElementsByClassName("tipo_cl");
            for (var i = 0; i < tipo_cl.length; i++) {
                tipo_cl[i].classList.remove('is-active');
            }

            const v = document.getElementById(x);
            v.classList.add('is-active');

        } catch (err) {
            console.log(err);
        }
    };

    const resetPage = () => {

    };

    const main = async () => {
        getChangerCitie();
        loadCurrent();
    };

    if (loadPage !== null) main();
};
