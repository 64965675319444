const headerFirtsLineDesktop = document.querySelector(
    ".main-header  .desktop-menu"
);
console.log(headerFirtsLineDesktop.offsetHeight);
const hideToFirstLineHeaderDesktop = scrollCurrentPositionY => {
    const currentViewreport = window.innerWidth;

    if (scrollCurrentPositionY > 300 && currentViewreport >= 1200) {
        headerFirtsLineDesktop.classList.add("js-is-scroll");
    } else {
        headerFirtsLineDesktop.classList.remove("js-is-scroll");
    }
};

module.exports = window.addEventListener("scroll", () => {
    hideToFirstLineHeaderDesktop(scrollY);
});
