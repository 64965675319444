export default () => {
    const label = document.querySelector(".anexo");
    const anexo = document.querySelector("#anexo");

    if (anexo) {
        anexo.addEventListener("change", () => {
            let vetorAnexo = anexo.value.split("\\");
            vetorAnexo = vetorAnexo[vetorAnexo.length - 1];

            label.textContent = vetorAnexo;
        });
    }
};
