const allOptionMobile = document.querySelectorAll(".main-menu-mobile .main-menu-mobile__item");

allOptionMobile.forEach((element) => {
    if(element.querySelector("ul")) {
        element.classList.remove("js-close-menu-mobile");
        element.querySelector("a").addEventListener("click", (event) => {event.preventDefault();});
        element.addEventListener("click", (event) => {


            let submenuAtual = element.querySelector("ul");

            if(submenuAtual.classList.contains("is-open")){
                allOptionMobile.forEach((option) => {
                    submenuAtual.classList.remove("is-open");
                });
            }

            else if(allOptionMobile){

                allOptionMobile.forEach((option) => {

                    option.querySelector("ul").classList.remove("is-open");
                if(element === option){
                    submenuAtual.classList.add("is-open");
                }
                });
            }
        });
    }
});


const allJsCloseMenu = document.querySelectorAll(".js-close-menu-mobile");
const xicon =  document.querySelector(".burger-icon-container");
allJsCloseMenu.forEach(element => {
    element.addEventListener("click", () => {
        xicon.classList.remove("is-open");
    });
});
